import { css, cx } from '@emotion/css';
import { Card, Text } from '@topremit/shared-web/components/elements';
import { useTranslation } from '@topremit/shared-web/hooks';
import { color } from '@topremit/shared-web/styles/color';
import { Dispatch, SetStateAction } from 'react';

interface IServiceHowCardProps {
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  currentStep: number;
}

export default function ServiceHowCard({
  step,
  setStep,
  currentStep,
}: IServiceHowCardProps) {
  const { t } = useTranslation();

  const isDisabledCard = step !== currentStep;

  function handleCardClick() {
    setStep(step - 1);
  }

  return (
    <Card
      column
      variant="outline"
      borderWidth={isDisabledCard ? 1 : 2}
      borderColor={isDisabledCard ? color.neutral200 : color.blue500}
      className={cx(styled.root, { disable: isDisabledCard })}
      onClick={handleCardClick}
    >
      <dt>
        <Text
          as="h3"
          minSize="p"
          maxSize="h5"
          className={cx('title', { 'mb-0': isDisabledCard })}
        >
          {`${step}. `} {t(`home:how.content.title_${step}`)}
        </Text>
      </dt>
      <dd className={styled.disabled(isDisabledCard)}>
        <Text as="p" minSize="xs" maxSize="p" className="description">
          {t(`home:how.content.desc_${step}`)}
        </Text>
      </dd>
    </Card>
  );
}

const styled = {
  root: css`
    width: 100%;
    padding: 1.5rem;
    border-radius: 1rem;
    &.disable {
      &:hover {
        cursor: pointer;
      }
      .title {
        color: var(--text-secondary);
      }
    }
    .mb-0 {
      margin-bottom: 0;
    }
    .description {
      color: var(--neutral-500);
      margin: 0;
    }
    dd {
      margin-left: 0;
    }
  `,
  disabled: (isDisabledCard) => css`
    display: ${isDisabledCard && 'none'};
  `,
};
